var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{staticClass:"bg-main-navbar custom-toggler",attrs:{"toggleable":"xl","sticky":""}},[_c('b-navbar-toggle',{attrs:{"target":"navbar-responsive-sidebar"}}),_c('b-sidebar',{attrs:{"id":"navbar-responsive-sidebar","title":"Navigation","backdrop-variant":"dark","bg-variant":"dark","text-variant":"light","backdrop":"","shadow":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('b-nav',{attrs:{"vertical":""}},[_c('h4',{attrs:{"id":"sidebar-no-header-title "}},[(_vm.isLogged)?_c('b-nav-item',{staticClass:"bg-warning text-dark"},[_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v(_vm._s(_vm.showBooColapseMenu))])],1):_vm._e()],1),_vm._l((_vm.routes.entries),function(route,i){return _c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.showThisTab(route) || _vm.showCollapseThisTab(route, true)),expression:"showThisTab(route) || showCollapseThisTab(route, true)"}],key:i,attrs:{"to":{
            path: _vm.removeParamsRouterPath(route.path, route.param_defaut),
          },"tag":route.collapse ? 'strong' : 'b-nav-item'}},[(!route.collapse)?_c('strong',{staticClass:"text-white fontStandard hoverButton"},[_c('i',{class:route.icon}),_vm._v(" "+_vm._s(route.name)+" ")]):_vm._e(),(route.collapse)?_c('b-nav-item-dropdown',{attrs:{"toggle-class":"text-white fontStandard hoverButton"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{class:route.icon}),_vm._v(" "+_vm._s(route.name)+" ")]},proxy:true}],null,true)},_vm._l((route.collapse),function(uRoute,i2){return _c('router-link',{key:i2,attrs:{"to":{
                path: ("" + (_vm.removeParamsRouterPath(route.path, [uRoute.path]))),
              },"tag":"b-dropdown-item"}},[_vm._v(_vm._s(uRoute.name))])}),1):_vm._e()],1)})],2),_c('b-button',{attrs:{"variant":"secondary","block":""},on:{"click":hide}},[_vm._v("Fermer le menu")])]}}])}),_c('b-collapse',{attrs:{"is-nav":""}},[_c('b-navbar-nav',_vm._l((_vm.routes.entries),function(route,i){return _c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.showThisTab(route)),expression:"showThisTab(route)"}],key:i,attrs:{"to":{ path: _vm.removeParamsRouterPath(route.path, route.param_defaut) },"tag":route.collapse ? 'strong' : 'b-nav-item'}},[(!route.collapse)?_c('strong',{staticClass:"text-white fontStandard hoverButton"},[_c('a',{staticClass:"text-white fontStandard hoverButton",attrs:{"to":{
              path: _vm.removeParamsRouterPath(route.path, route.param_defaut),
            }}},[_c('i',{class:route.icon}),_vm._v(" "+_vm._s(route.name)+" ")])]):_vm._e(),(route.collapse)?_c('b-nav-item-dropdown',{attrs:{"toggle-class":"text-white fontStandard hoverButton"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{class:route.icon}),_vm._v(" "+_vm._s(route.name)+" ")]},proxy:true}],null,true)},_vm._l((route.collapse),function(uRoute,i2){return _c('router-link',{key:i2,attrs:{"to":{
              path: ("" + (_vm.removeParamsRouterPath(route.path, [uRoute.path]))),
            },"tag":"b-dropdown-item"}},[_c('a',{staticClass:"text-dark fontStandard hoverButton",attrs:{"href":{
                path: ("" + (_vm.removeParamsRouterPath(route.path, [uRoute.path]))),
              }}},[_vm._v(_vm._s(uRoute.name))])])}),1):_vm._e()],1)}),1),(_vm.isLogged)?_c('b-navbar-nav',{staticClass:"ml-auto"},[(!_vm.isHomeRoute)?_c('b-avatar',{staticClass:"__avatar_picture_account",attrs:{"variant":"warning","text":_vm.showBooAvatar,"size":"2.5em"}}):_vm._e(),(_vm.isHomeRoute)?_c('b-nav-item',[_c('strong',{staticClass:"text-warning fontStandard hoverButton",on:{"click":function($event){return _vm.$router.push('/account/dashboard')}}},[_c('i',{staticClass:"fas fa-user-edit"}),_vm._v(" Mon compte ")])]):_c('b-nav-item-dropdown',{attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('strong',{staticClass:"text-warning fontStandard hoverButton"},[_vm._v(_vm._s(_vm.showBooColapseMenu))])]},proxy:true}],null,false,1328269239)},_vm._l((_vm.routes.entries),function(route,i){return _c('router-link',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCollapseThisTab(route, true)),expression:"showCollapseThisTab(route, true)"}],key:i,attrs:{"to":{
            path: _vm.removeParamsRouterPath(route.path, route.param_defaut),
          },"tag":"b-dropdown-item"}},[_c('i',{class:route.icon},[_vm._v(_vm._s(" " + route.name))])])}),1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }