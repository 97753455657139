<template>
  <b-navbar toggleable="xl" class="bg-main-navbar custom-toggler" sticky>
    <b-navbar-toggle target="navbar-responsive-sidebar" />

    <b-sidebar
      id="navbar-responsive-sidebar"
      title="Navigation"
      backdrop-variant="dark"
      bg-variant="dark"
      text-variant="light"
      backdrop
      shadow
    >
      <template v-slot:default="{ hide }">
        <b-nav vertical>
          <h4 id="sidebar-no-header-title ">
            <b-nav-item v-if="isLogged" class="bg-warning text-dark">
              <b-badge variant="warning">{{ showBooColapseMenu }}</b-badge>
              <!-- <strong class="text-warning fontStandard hoverButton"></strong> -->
            </b-nav-item>
          </h4>

          <router-link
            v-for="(route, i) in routes.entries"
            :key="i"
            v-show="showThisTab(route) || showCollapseThisTab(route, true)"
            :to="{
              path: removeParamsRouterPath(route.path, route.param_defaut),
            }"
            :tag="route.collapse ? 'strong' : 'b-nav-item'"
          >
            <!-- Text -->
            <strong
              class="text-white fontStandard hoverButton"
              v-if="!route.collapse"
            >
              <i :class="route.icon" />
              {{ route.name }}
            </strong>
            <!-- Text -->

            <!-- Collapse -->
            <b-nav-item-dropdown
              v-if="route.collapse"
              toggle-class="text-white fontStandard hoverButton"
            >
              <template v-slot:button-content>
                <i :class="route.icon" />
                {{ route.name }}
              </template>
              <router-link
                v-for="(uRoute, i2) in route.collapse"
                :key="i2"
                :to="{
                  path: `${removeParamsRouterPath(route.path, [uRoute.path])}`,
                }"
                tag="b-dropdown-item"
                >{{ uRoute.name }}</router-link
              >
            </b-nav-item-dropdown>
            <!-- Collapse -->
          </router-link>
        </b-nav>

        <b-button variant="secondary" block @click="hide"
          >Fermer le menu</b-button
        >
      </template>
    </b-sidebar>

    <b-collapse is-nav>
      <b-navbar-nav>
        <router-link
          v-for="(route, i) in routes.entries"
          :key="i"
          v-show="showThisTab(route)"
          :to="{ path: removeParamsRouterPath(route.path, route.param_defaut) }"
          :tag="route.collapse ? 'strong' : 'b-nav-item'"
        >
          <!-- Text -->
          <strong
            class="text-white fontStandard hoverButton"
            v-if="!route.collapse"
          >
            <a
              class="text-white fontStandard hoverButton"
              :to="{
                path: removeParamsRouterPath(route.path, route.param_defaut),
              }"
            >
              <i :class="route.icon" />
              {{ route.name }}
            </a>
          </strong>
          <!-- Text -->

          <!-- Collapse -->
          <b-nav-item-dropdown
            v-if="route.collapse"
            toggle-class="text-white fontStandard hoverButton"
          >
            <template v-slot:button-content>
              <i :class="route.icon" />
              {{ route.name }}
            </template>
            <router-link
              v-for="(uRoute, i2) in route.collapse"
              :key="i2"
              :to="{
                path: `${removeParamsRouterPath(route.path, [uRoute.path])}`,
              }"
              tag="b-dropdown-item"
            >
              <a
                class="text-dark fontStandard hoverButton"
                :href="{
                  path: `${removeParamsRouterPath(route.path, [uRoute.path])}`,
                }"
                >{{ uRoute.name }}</a
              >
            </router-link>
          </b-nav-item-dropdown>
          <!-- Collapse -->
        </router-link>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto" v-if="isLogged">
        <b-avatar
          variant="warning"
          :text="showBooAvatar"
          size="2.5em"
          class="__avatar_picture_account"
          v-if="!isHomeRoute"
        />
        <b-nav-item v-if="isHomeRoute">
          <strong
            class="text-warning fontStandard hoverButton"
            @click="$router.push('/account/dashboard')"
            ><i class="fas fa-user-edit" /> Mon compte
          </strong>
        </b-nav-item>

        <b-nav-item-dropdown v-else right>
          <template v-slot:button-content>
            <strong class="text-warning fontStandard hoverButton">{{
              showBooColapseMenu
            }}</strong>
          </template>
          <router-link
            v-for="(route, i) in routes.entries"
            :key="i"
            v-show="showCollapseThisTab(route, true)"
            :to="{
              path: removeParamsRouterPath(route.path, route.param_defaut),
            }"
            tag="b-dropdown-item"
          >
            <i :class="route.icon">{{ " " + route.name }}</i>
          </router-link>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import routes from "@/router/routes";

export default {
  components: { BTextUppercase: () => import("@/components/BTextUppercase") },
  data() {
    return {
      current: 0,
      routes: routes,
    };
  },
  computed: {
    ...mapState(["events"]),
    isHomeRoute() {
      return this.$route.path === "/" ? true : false;
    },
    isLogged() {
      const user = this.$root.getContent();
      return user === null || !user.hasOwnProperty("loggedIn") || !user.loggedIn
        ? false
        : true;
    },
    showBooAvatar() {
      const user = this.$root.getContent();
      const extraInfo = this.$root.getExtraInfo();
      if (user === null || !user.hasOwnProperty("loggedIn") || !user.loggedIn)
        return "";

      const firstName = extraInfo.hasOwnProperty("firstName")
        ? this.showLimitedString(extraInfo.firstName, 1)
        : "-";

      const lastName = extraInfo.hasOwnProperty("lastName")
        ? this.showLimitedString(extraInfo.lastName, 1)
        : "-";

      return firstName.length === 0 || lastName.length === 0
        ? ``
        : `${firstName}/${lastName}`;
    },
    showBooColapseMenu() {
      const user = this.$root.getContent();
      const extraInfo = this.$root.getExtraInfo();
      if (user === null || !user.hasOwnProperty("loggedIn") || !user.loggedIn)
        return "Mon compte";

      const firstName = extraInfo.hasOwnProperty("firstName")
        ? this.showLimitedString(extraInfo.firstName, 15)
        : "-";

      const lastName = extraInfo.hasOwnProperty("lastName")
        ? this.showLimitedString(extraInfo.lastName, 1)
        : "-";

      return firstName.length === 0 || lastName.length === 0
        ? `Mon compte`
        : `${firstName} ${lastName}.`;
    },
  },
  methods: {
    removeParamsRouterPath(path, params_replace) {
      if (path && Array.isArray(params_replace) && path.indexOf(":") != -1) {
        let number = 0;

        while (path.indexOf(":") != -1) {
          const p_replace = params_replace[number];
          const replace =
            typeof p_replace !== "undefined" && p_replace !== ""
              ? params_replace[number]
              : "id";

          path = path.replace(this.foundMeTheRouterParam(path), replace);
          number++;
        }
      }
      return path;
    },
    foundMeTheRouterParam(path) {
      let param = "";
      for (let i = path.indexOf(":"); i < path.length; i++) {
        if (path.charAt(i) === "/") break;
        else param += path.charAt(i);
      }
      return param;
    },
    showThisTab(route) {
      if (this.$route.path === route.path) return false;
      else if (!this.routes.showEveryoneExcept404(route.path)) return false;
      return this.loginRouter(route);
    },
    showCollapseThisTab(route, showPrivateMenu = false) {
      if (!this.routes.canShowThisRoute(route.path, showPrivateMenu))
        return false;
      return this.loginRouter(route);
    },

    loginRouter(route) {
      const user = this.$root.getContent();
      if (user === null) return false;
      const meta = route.meta;

      const requiresAuth = meta.hasOwnProperty("requiresAuth")
        ? meta.requiresAuth
        : true;
      const access = meta.hasOwnProperty("access") ? meta.access : [];
      const loggedIn = user.hasOwnProperty("loggedIn") ? user.loggedIn : false;
      const rank =
        user.hasOwnProperty("rank") && loggedIn ? user.rank : "guest";

      if (requiresAuth) {
        if (access.indexOf(rank) === -1) return false;
        else if (!loggedIn) return false;
      }

      if (loggedIn) {
        if (route.path === "/account/login") return false;
        else if (route.path === "/account/register") return false;
      }

      return true;
    },

    showLimitedString(string = "", limit = 1) {
      if (limit >= string.length) {
        limit = string.length;
      }

      let text = "";
      for (let i = 0; i < limit; i++) {
        text += i === 0 ? string[i].toUpperCase() : string[i];
      }
      return text;
    },
  },
};
</script>

<style scoped>
.disabled_cursor_pointer {
  cursor: none;
}

.bg-main-navbar {
  background: #163a58dd;
  box-shadow: 2px 4px 4px #0e263a;
}

.navbar-toggler {
  border-color: #ffc107;
  background-color: #ffc107;
  background: #ffc107;
  color: #ffc107;
}

.hoverButton {
  transition: 0.5s;
}

.hoverButton:hover {
  transition: 0.5s;
  color: #ffc107 !important;
}

.router-link-exact-active {
  color: white !important;
}

@media (max-width: 1024px) {
  .__avatar_picture_account {
    display: none;
  }
}
</style>
